
import gql from 'graphql-tag';


const GET_PLACES = gql`
query Places {
  places {
    id
    name
    long_name
    map
    address
  }
}
`;

const GET_PLACE = gql`
query Place($place_id:ID!) {
  place(id:$place_id) {
    id
    name
    long_name
    address
    map
  }
}
`;

const GET_PLACE_INFO = gql`
query PlaceInfo($place_id:ID!) {
  place_info(place_id:$place_id) {
    place {
      id
      name
      long_name
      address
      map
    }
    zones {
      id
      name
      long_name
      place_id
      map_zone_key
      free_zone
      primary_color
      secondary_color
    }
    seats {
      id
      zone_id
      name
      type
      capacity
      row_label_base
      col_label_base
      box_capacity_left
      box_capacity_right
      box_capacity_top
      box_capacity_bottom
      map_position_x
      map_position_y
      map_position_angle
      map_sector_angle
    }
  }
}
`;


const GET_ZONES = gql`
  query Zones($place_id:ID) {
    zones(place_id:$place_id) {
      id
      name
      long_name
      place_id
      map_zone_key
      free_zone
      primary_color
      secondary_color
    }
  }
`;

const GET_ZONES_WITH_PLACE_AND_SEATS = gql`
  query ZonesWithPlace($place_id:ID!) {
    zones(place_id:$place_id) {
      id
      name
      long_name
      place_id
      map_zone_key
      free_zone
      primary_color
      secondary_color
    }
    place(id:$place_id) {
      id
      name
      long_name
      address
      map
    }
    seats(place_id:$place_id) {
      id
      zone_id
      place_id
      name
      type
      capacity
      row_label_base
      col_label_base
      box_capacity_left
      box_capacity_right
      box_capacity_top
      box_capacity_bottom
      map_position_x
      map_position_y
      map_position_angle
      map_sector_angle
    }
  } 
`;

const GET_ZONE_PRICES = gql`
  query ZonePrices($price_plan_id:ID,$zone_id:ID) {
    zonePrices(price_plan_id:$price_plan_id,zone_id:$zone_id) {
      id
      name
      cost
      sale_student
      zone_id
      price_plan_id
    }
  }
`;


const GET_PRICE_PLANS = gql`
  query PricePlans($place_id:ID) {
    pricePlans(place_id:$place_id) {
      id
      name
      place_id
    }
  }
`;

const GET_SEATS = gql`
  query Seats($zone_id:ID) {
    seats(zone_id:$zone_id) {
      id
      zone_id
      place_id
      name
      type
      capacity
      row_label_base
      col_label_base
      box_capacity_left
      box_capacity_right
      box_capacity_top
      box_capacity_bottom
      map_position_x
      map_position_y
      map_position_angle
      map_sector_angle
    }
  }
`;

const GET_SEATS_WITH_ZONE_AND_PLACE=gql`
query SeatsWithZonePlace($zone_id:ID!,$place_id:ID!) {
  seats(zone_id:$zone_id) {
    id
    zone_id
    place_id
    name
    type
    capacity
    row_label_base
    col_label_base
    box_capacity_left
    box_capacity_right
    box_capacity_top
    box_capacity_bottom
    map_position_x
    map_position_y
    map_position_angle
    map_sector_angle
  }
  zone(id:$zone_id) {
    id
    name
    long_name
    place_id
    map_zone_key
    free_zone
    primary_color
    secondary_color
  }
  place(id:$place_id) {
    id
    name
    long_name
    address
    map
  }
}
`;

const GET_EVENTS = gql`
  query Events {
    events {
      id
      name
      status
      private
      test_mode
      place_id
      comment
      image_id
      date
      auto_open_date
      auto_suspend_date
    }
  }
`;

const GET_EVENT_PRICE_PLANS = gql`
  query EventPricePlans($event_id:ID!) {
    eventPricePlans(event_id:$event_id) {
      id
      active
      name
      price_plan_id
    }
  }
`;


const GET_IMAGES = gql`
  query Images {
    images {
      id
      name
      url
      file_name
      width
      height
      type
    }
  }
`;

const GET_SCHOOLS = gql`
  query Schools {
    schools {
      id
      name
    }
  }
`;



export{
    GET_PLACES,
    GET_PLACE,
    GET_PLACE_INFO,
    GET_ZONES,
    GET_ZONES_WITH_PLACE_AND_SEATS,
    GET_SEATS,
    GET_SEATS_WITH_ZONE_AND_PLACE,
    GET_PRICE_PLANS,
    GET_ZONE_PRICES,
    GET_EVENTS,
    GET_IMAGES,
    GET_SCHOOLS,
    GET_EVENT_PRICE_PLANS,
};